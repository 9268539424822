.header_container {
  padding: 10px 20px;
  background: var(--pop-up-background-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #27323829;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support_text {
  color: var(--primary);
}

.details_header,
.interim_details_header {
  background: #0071ce 0% 0% no-repeat padding-box;
  padding-bottom: 14px;
  padding-top: 14px;
}

.back_text {
  color: #ffffff;
}

.closeButton {
  font-size: 22px;
}

.closeButtonOnboardingClass {
  font-size: 29px;
  color: #ffffff;
}

.helpImage {
  width: 40%;
}

.phone_image {
  filter: var(--icon-filter);
}
