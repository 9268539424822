.fixed_bottom {
  position: fixed;
  max-width: 100%;
  bottom: 7px;
  left: 0.75rem;
  right: 0.75rem;
}

.fixed_loader {
  position: fixed;
  max-width: 100%;
  bottom: 7px;
  left: 0.75rem;
  right: 0.75rem;
}

@media screen and (min-width: 600px) {
  .fixed_bottom {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 598px;
    padding: 0px 12px;
  }
}

.height_full {
  height: 100%;
}
