/* These are global css need one smae type of things required to many times */
:root {
  --primary: #0096ff;
  --secondary: #00529a;
  --background-color: #ffffff;
  --heading-color: #273238;
  --paragraph-color: #5f7687;
  --pop-up-background-color: var(--background-color);
  --background-wrapper-color: #eff6ff;
  --border-color: #e5e7eb;
  --disabled-color: var(--paragraph-color);
  --dot-color: var(--primary);
  --stepper-label-color: var(--secondary);
  --stepper-background-color: var(--background-wrapper-color);
  --snack-bar-background-color: #ffeaea;
  --snack-bar-text-color: #ca5d4e;
  --icon-filter: brightness(0) saturate(100%) invert(35%) sepia(92%)
    saturate(1998%) hue-rotate(188deg) brightness(107%) contrast(107%);
}

body {
  font-family: "roboto-regular";
  font-size: 0.8rem;
  max-width: 598px;
  margin-left: auto;
  margin-right: auto;
  background: var(--background-color);
}
h1 {
  color: var(--heading-color);
  font-weight: 600;
  font-size: 1.125rem;
  font-family: "Comfortaa", "Regular";
  opacity: 100;
}

h2 {
  color: var(--heading-color);
  font-weight: 600;
  font-size: 0.875rem;
  font-family: "Roboto-Light";
  opacity: 100;
}

h3 {
  color: var(--heading-color);
  font-weight: 600;
  font-size: 0.75rem;
  font-family: "Roboto-Light";
  opacity: 100;
}

p {
  font-size: 0.75rem;
  font-family: "roboto-regular";
  word-break: break-word;
  color: var(--paragraph-color);
}

.font-20px {
  font-size: 20px !important;
}

.font-14dp {
  font-size: 0.875rem !important ;
}

.font-10px {
  font-size: 10px;
}

.flex-basis-2 {
  flex-basis: 48%;
}

.flex-basis-1 {
  flex-basis: 100%;
}

.custom-image {
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.padding-50px {
  padding-bottom: 20px;
}

.opacity-7 {
  opacity: 0.7;
}
.text-align-right {
  text-align: right;
}

.text-primary{
  color: var(--primary);
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.margin_bottom {
  /* for  fixing the button */
  margin-bottom: 80px;
}

.fixed_bottom {
  position: fixed;
  max-width: 100%;
  bottom: 7px;
  left: 0.75rem;
  right: 0.75rem;
}

@media screen and (min-width: 600px) {
  .fixed_bottom {
    left: auto;
    right: auto;
    /* width: 100%; */
    min-width: 599px;
  }
}
