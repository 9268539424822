.global-loader {
  z-index: 1024;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0.1;
}
@keyframes ldio-igiffh5vp5-1 {
  0% {
    top: 30px;
    height: 60px;
  }
  50% {
    top: 38px;
    height: 30px;
  }
  100% {
    top: 38px;
    height: 30px;
  }
}
@keyframes ldio-igiffh5vp5-2 {
  0% {
    top: 30px;
    height: 60px;
  }
  50% {
    top: 38px;
    height: 30px;
  }
  100% {
    top: 38px;
    height: 30px;
  }
}
@keyframes ldio-igiffh5vp5-3 {
  0% {
    top: 30px;
    height: 60px;
  }
  50% {
    top: 38px;
    height: 30px;
  }
  100% {
    top: 38px;
    height: 30px;
  }
}

.ldio-igiffh5vp5 div {
  position: absolute;
  width: 8px;
}

.ldio-igiffh5vp5 div:nth-child(1) {
  left: 20px;
  background: #6b100e;
  animation: ldio-igiffh5vp5-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.9s;
}

.ldio-igiffh5vp5 div:nth-child(2) {
  left: 33px;
  background: #ffb033;
  animation: ldio-igiffh5vp5-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.6s;
}
.ldio-igiffh5vp5 div:nth-child(3) {
  left: 46px;
  background: #3d757c;
  animation: ldio-igiffh5vp5-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.3s;
}

.loadingio-spinner-pulse-l6imaz837x {
  width: 75px;
  height: 73px;
  display: inline-block;
  overflow: hidden;
}

.ldio-igiffh5vp5 {
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
