.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.modal-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  max-width: 600px;
  /* max width is for making mobile view-in laptop also  */
}

.modal {
  z-index: 100;
  background: var(--pop-up-background-color);
  position: absolute;
  bottom: 0;
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.modal-close-button {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  color: var(--primary);
  cursor: pointer;
  border: none;
  display: flex;
  align-items: flex-start;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}
