@font-face {
  font-family: "roboto-regular";
  src: local("roboto"),
    url(assets/fonts/roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: local("roboto"),
    url(assets/fonts/roboto/Roboto-Light.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI","Roboto-Light", "roboto-regular",;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
