@keyframes ldio-fqb5up37nb {
  0% { transform: translate(10px,5px) scale(0); }
 25% { transform: translate(10px,5px) scale(0); }
 50% { transform: translate(10px,5px) scale(1); }
 75% { transform: translate(30px,5px) scale(1); }
100% { transform: translate(60px,5px) scale(1); }
}
@keyframes ldio-fqb5up37nb-r {
  0% { transform: translate(60px,5px) scale(1); }
100% { transform: translate(60px,5px) scale(0); }
}
@keyframes ldio-fqb5up37nb-c {
  0% { background: #6b100e }
 25% { background: #3d757c }
 50% { background: #ffb033 }
 75% { background: #ca5d4e }
100% { background: #6b100e }
}
.ldio-fqb5up37nb div {
 position: absolute;
 width: 15px;
 height: 15px;
 border-radius: 50%;
 transform: translate(20px,30px) scale(1);
 background: #6b100e;
 animation: ldio-fqb5up37nb 1.1111111111111112s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-fqb5up37nb div:nth-child(1) {
 background: #ca5d4e;
 transform: translate(20px,30px) scale(1);
 animation: ldio-fqb5up37nb-r 0.2777777777777778s infinite cubic-bezier(0,0.5,0.5,1), ldio-fqb5up37nb-c 1.1111111111111112s infinite step-start;
}.ldio-fqb5up37nb div:nth-child(2) {
 animation-delay: -0.2777777777777778s;
 background: #6b100e;
}.ldio-fqb5up37nb div:nth-child(3) {
 animation-delay: -0.5555555555555556s;
 background: #ca5d4e;
}.ldio-fqb5up37nb div:nth-child(4) {
 animation-delay: -0.8333333333333333s;
 background: #ffb033;
}.ldio-fqb5up37nb div:nth-child(5) {
 animation-delay: -1.1111111111111112s;
 background: #3d757c;
}
.loadingio-spinner-ellipsis-qje3l0vorsa {
  width: 76px;
  height: 20px;
 display: inline-block;
 overflow: hidden;
 background: none;
}
.ldio-fqb5up37nb {
 width: 100%;
 height: 100%;
 position: relative;
 transform: translateZ(0) scale(1);
 backface-visibility: hidden;
 transform-origin: 0 0; /* see note above */
}
.ldio-fqb5up37nb div { box-sizing: content-box; }