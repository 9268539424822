.error {
  padding: 10px;
  display: flex;
  border: 1px solid "#FFE0E9";
  color: "#FFE0E9";
  background-color: #ffe8ef;
  border-radius: 8px
}

.error_color {
  display: flex;
  color: "#FFE0E9";
  justify-content: center;
  align-items: center;
}

.warning {
  padding: 10px;
  display: flex;
  border: 1px solid #ff7848;
  color: #ff7848;
  background-color: #ffe2d8;
  border-radius: 8px
}

.warning_color {
  display: flex;
  color: #ff7848;
  justify-content: center;
  align-items: center;
}

.info {
  border: 1px solid #2f4580;
  color: #2f4580;
  background-color: #f4f6ff;
  border-radius: 8px
}

.info_color {
  padding: 10px;
  display: flex;
  color: #2f4580;
  justify-content: center;
  align-items: center;
}

.success {
  border: 1px solid #17aaa5;
  color: #17aaa5;
  background-color: #dcf5f3;
  border-radius: 8px
}

.success_color {
  padding: 10px;
  display: flex;
  color: #17aaa5;
  justify-content: center;
  align-items: center;
}
